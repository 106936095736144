/* eslint-disable no-template-curly-in-string */
export function replaceItemInArrById(item, listItems) {
  return listItems.map((obj) => {
    if (obj.id === item.id) {
      return item;
    } else {
      return obj;
    }
  });
}
