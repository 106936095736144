import axios from 'axios';
import { message } from 'antd';
import { baseUrl, getAuthorizedHeaders } from 'api/base';
import { trackUploadedImage } from 'api/imageApis';

const signUrl = `${baseUrl}/api/v1/images/sign-s3`;

export const IMAGES_EXAMPLES = [
  {
    id: 11,
    thumb_url:
      'https://imuanha-assets-dev.sgp1.digitaloceanspaces.com/test_images/1/images_636788400080455900_Done-14-jpg.jpg',
    original_url:
      'https://imuanha-assets-dev.sgp1.digitaloceanspaces.com/test_images/1/images_636788400080455900_Done-14-jpg.jpg'
  },
  {
    id: 12,
    thumb_url:
      'https://imuanha-assets-dev.sgp1.digitaloceanspaces.com/test_images/1/images_636788400021080762_Done-3-jpg.jpg',
    original_url:
      'https://imuanha-assets-dev.sgp1.digitaloceanspaces.com/test_images/1/images_636788400021080762_Done-3-jpg.jpg'
  },
  {
    id: 8,
    thumb_url:
      'https://imuanha-assets-dev.sgp1.digitaloceanspaces.com/test_images/1/images_636788400004830851_Done-1-jpg.jpg',
    original_url:
      'https://imuanha-assets-dev.sgp1.digitaloceanspaces.com/test_images/1/images_636788400004830851_Done-1-jpg.jpg'
  },
  {
    id: 9,
    thumb_url:
      'https://imuanha-assets-dev.sgp1.digitaloceanspaces.com/test_images/1/images_636788400020143312_Done-4-jpg.jpg',
    original_url:
      'https://imuanha-assets-dev.sgp1.digitaloceanspaces.com/test_images/1/images_636788400020143312_Done-4-jpg.jpg'
  }
];

export const customAntdUploadRequestHandler = async ({
  file,
  onError,
  onSuccess,
  onProgress,
  setLoading,
  uploadToFolder
}) => {
  const authHeaders = getAuthorizedHeaders();

  const fileType = file.type;
  const fileName = file.name;

  const key = `images/${fileName}`;
  setLoading(true);

  const signReqOptions = {
    folder: uploadToFolder,
    file_name: file.name,
    new_file_name: key
  };

  try {
    const response = await axios.post(
      `${signUrl}`,
      signReqOptions,
      authHeaders
    );

    const signedData = response.data;

    if (response.status >= 400 || !signedData?.signed_url) {
      const messageError = signedData?.message || 'Error';
      onError(messageError);
      message.error(`Đã có lỗi xảy ra: ${messageError} (code: 1)`);
      return false;
    }

    var signedUrl = signedData.signed_url;

    var options = {
      onUploadProgress: (event) => {
        const { loaded, total } = event;
        onProgress(
          {
            percent: Math.round((loaded / total) * 100)
          },
          file
        );
      },
      headers: {
        'Content-Type': fileType,
        'x-amz-acl': 'public-read'
      }
    };

    const uploadResult = await axios.put(signedUrl, file, options);

    if (uploadResult.status === 200) {
      const trackParams = {
        usage_for: 'product_images',
        s3_key: signedData.s3_key,
        s3_url: signedData.s3_url,
        file_name: file.name,
        file_size: file.size,
        file_type: file.type
      };
      const response = await trackUploadedImage(trackParams);
      if (response.status && response.data) {
        file.thumbUrl = response.data.thumb_url;
        file.url = response.data.original_url;
        file.record = response.data;
        onSuccess(response.data, file);
      } else {
        onError(`Đã có lỗi xảy ra: (code: 4)`);
      }
    } else {
      onError('Đã có lỗi xảy ra (code 2)');
    }
    setLoading(false);
  } catch (error) {
    message.error(`Đã có lỗi xảy ra: ${error.message} (code: 3)`);
    onError(error);
    setLoading(false);
  }
};

export const convertToAntdFileList = (images) => {
  return images.map((image) => ({
    uid: image.id,
    name: image.file_name,
    status: 'done',
    url: image.original_url,
    thumbUrl: image.thumb_url,
    record: image
  }));
};
