import React, { useState, useCallback, useEffect } from 'react';
import { Button, message } from 'antd';
import { ContentRow } from 'components/ThemeComponents';
import { userListAtom } from './users_page/states';
import { useRecoilState } from 'recoil';

import UserTable from './users_page/listing_users/components/UserTable';
import AppHeader from 'components/AppHeader';
import userApis from 'api/userApis';

import AddNewUserModal from './users_page/add_user/components/AddNewUserModal';

export default function UsersPage() {
  const [userList, setUserList] = useRecoilState(userListAtom);

  const [isModalAddNewUserVisible, setIsModalAddNewUserVisible] =
    useState(false);
  const [isLoading, setIsLoading] = useState(false);

  const onAddNewUserClick = useCallback(() => {
    setIsModalAddNewUserVisible(true);
  }, []);

  useEffect(() => {
    async function fetchListUsers() {
      setIsLoading(true);
      try {
        const res = await userApis.list();
        const items = res?.data || [];
        if (items.length > 0) {
          setUserList(items);
        }
      } catch (error) {
        message.info(error.message);
      }
      setIsLoading(false);
    }

    fetchListUsers();
  }, [setUserList]);

  return (
    <>
      <AppHeader
        pageTitle="Quản lý tài khoản"
        pageHeaderExtras={
          <>
            <Button type="primary" onClick={onAddNewUserClick}>
              Thêm tài khoản
            </Button>
          </>
        }
      />

      <ContentRow capped>
        <UserTable loading={isLoading} data={userList} />
      </ContentRow>

      <AddNewUserModal
        isModalVisible={isModalAddNewUserVisible}
        setIsModalVisible={setIsModalAddNewUserVisible}
      ></AddNewUserModal>
    </>
  );
}
