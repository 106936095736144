import React, { useState } from 'react';
import { Layout } from 'antd';
import Footer from '../layouts/Footer';
const { Content } = Layout;

const Home = () => {
  return (
    <>
      <Content
        style={{
          margin: '0 16px',
        }}
      >
        <div
          className='site-layout-background'
          style={{
            padding: 24,
            minHeight: 360,
          }}
        >
          Welcome to mktsoft, this is home!
        </div>
      </Content>
      <Footer />
    </>
  );
};

export default Home;
