import React from "react";
import { Form, Input } from "antd";

export default function NewCustomerForm({ form }) {
  return (
    <Form
      name="contest"
      form={form}
      layout="vertical"
      size="large"
      autoComplete="off"
    >
      <div style={{ margin: "1em 0" }}>
        <strong>
          Các dạng cấu trúc đăng nhập "ID|PASSWORD|2FA" ,
          "ID|PASSWORD|2FA|EMAIL" , "ID|PASSWORD" , "COOKIES"
        </strong>
      </div>

      <Form.Item label="Enter xuống dòng cho mỗi tài khoản" name="credentials">
        <Input.TextArea
          size="large"
          placeholder="Enter xuống dòng cho mỗi tài khoản"
          style={{
            height: 150,
          }}
          value={
            "100057738407008|vAn7gnYnV9Yaiqm|KX4OX6BTVVOYYPFFPJKOHLKQLEPIRX35"
          }
        />
      </Form.Item>
    </Form>
  );
}
