import React from 'react';

// Login.jsx
export default function GuidePage() {
  return (
    <div>
      <h1>This is the GuidePage</h1>
    </div>
  );
}
