import { message } from 'antd';
import React from 'react';
import { createContext, useContext, useMemo } from 'react';
import { useAuth } from 'features/auth/hooks';

const AuthContext = createContext({});

export const AuthProvider = ({ children, ...props }) => {
  const { login, user, logout } = useAuth();

  const value = useMemo(
    () => ({
      user,
      login,
      logout
    }),
    [login, logout, user]
  );

  return (
    <AuthContext.Provider value={{ ...value, ...props }}>
      {children}
    </AuthContext.Provider>
  );
};
