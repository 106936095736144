import React, { useEffect } from 'react';

import Navigation from 'components/Navigation';
import { Navigate, useOutlet } from 'react-router-dom';
import { useAuth } from 'features/auth/hooks';
import { Layout } from 'antd';
const { Sider } = Layout;

export default function Authenticated() {
  const { user } = useAuth();

  // useEffect(async () => {
  //   await refreshToken();
  // }, []);

  const outlet = useOutlet();

  if (!user) {
    return <Navigate to="/login" />;
  }

  return (
    <>
      <Layout hasSider>
        <Sider
          style={{
            overflow: 'auto',
            height: '100vh',
            position: 'fixed',
            left: 0,
            top: 0,
            bottom: 0
          }}
        >
          <div className="logo">
            <a href="/">Admin</a>
          </div>
          <Navigation />
        </Sider>
        <Layout
          className="site-layout"
          style={{
            marginLeft: 200
          }}
        >
          {outlet}
        </Layout>
      </Layout>
    </>
  );
}
