import React from 'react';
import styled from 'styled-components';
import { Layout, PageHeader } from 'antd';

const StyledHead = styled(Layout.Header)`
  height: auto;
  padding: 0.5rem 0;
  background-color: #fff;
  box-shadow: @box-shadow-base;
`;

const StyledPageHeader = styled(PageHeader)`
  padding: 0 0.75rem;
  margin: 0;
`;

export default function MktHeader({
  pageCrumbs = null,
  pageTitle = null,
  tags = null,
  pageHeaderExtras = null,
  ...rest
}) {
  return (
    <StyledHead>
      {pageCrumbs}
      <StyledPageHeader
        title={pageTitle}
        tags={tags}
        ghost={false}
        extra={pageHeaderExtras}
        {...rest}
      />
    </StyledHead>
  );
}
