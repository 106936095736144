import { Form, message, Modal } from "antd";
import React from "react";
import NewCustomerForm from "./NewCustomerForm";
import userApis from "api/userApis";

const AddNewUserModal = ({ isModalVisible, setIsModalVisible }) => {
  const [form] = Form.useForm();

  const handleOk = async () => {
    const fields = form.getFieldsValue();

    try {
      const response = await userApis.create(fields);
      if (response.code === 200) {
        message.success("Successfully created!");
      } else {
        const errors = buildErrors(response);
        if (errors.length > 0) {
          message.error(errors.join("\n"));
        } else {
          message.error("Đã có lỗi xảy ra.");
        }
      }
    } catch (error) {
      message.error(error.message);
    }

    setIsModalVisible(false);
    window.location.reload();
  };

  function buildErrors(response) {
    try {
      return response.message.errors.map(
        (err, index) => `Dòng ${index + 1}: ${err.errors.errors[0].message}`
      );
    } catch (error) {
      return [];
    }
  }

  const handleCancel = () => {
    setIsModalVisible(false);
  };

  return (
    <>
      <Modal
        title="Thêm tài khoản"
        open={isModalVisible}
        onOk={handleOk}
        onCancel={handleCancel}
      >
        <NewCustomerForm form={form} />
      </Modal>
    </>
  );
};

export default AddNewUserModal;
