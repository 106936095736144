import React, { useEffect, useState } from 'react';
import { Form, message, Modal, Typography } from 'antd';
import userApis from 'api/userApis';
import { useSetRecoilState } from 'recoil';
import { userListAtom } from 'pages/users_page/states';
import UpdateUserForm from './UpdateUserForm';
import { replaceItemInArrById } from 'helpers/arrayHelper';
import { defaultDateFormat } from 'helpers/dateTimeHelper';
import moment from 'moment';

export default function UpdateUserModal({
  isModalVisible,
  setIsModalVisible,
  user
}) {
  const [form] = Form.useForm();
  const setUserList = useSetRecoilState(userListAtom);
  const [isUpdating, setIsUpdating] = useState(false);

  useEffect(() => {
    form.setFieldsValue({
      ...user,
      // convert birthday từ string sang moment để truyền xuống user form
      birthday:
        user.birthday !== null
          ? moment(user.birthday, defaultDateFormat)
          : undefined
    });
  }, [user, form]);

  const validateForm = () => {
    form
      .validateFields()
      .then((values) => {
        handleOk();
      })
      .catch((info) => {
        message.error('Sai định dạng');
        console.log('Validate Failed:', info);
      });
  };

  const handleOk = async () => {
    setIsUpdating(true);
    const fields = form.getFieldsValue();

    try {
      const params = {
        ...fields
      };
      const response = await userApis.update(user.id, params);
      if (response.code === 200) {
        message.success('Cập nhật thông tin tài khoản thành công.');
        const newUser = response.data;

        setUserList((userList) => {
          // TODO: Cần tách phần này ra để có thể reuse lại khi cần. P/s: done
          const newList = replaceItemInArrById(newUser, userList);
          return [...newList];
        });

        setIsModalVisible(false);
        form.resetFields();
      } else {
        const errors = buildErrors(response);
        if (errors.length > 0) {
          message.error(errors.join('\n'));
        } else {
          message.error('Đã có lỗi xảy ra.');
        }
      }
    } catch (error) {
      message.error(error.message);
    }
    setIsUpdating(false);
  };

  function buildErrors(response) {
    try {
      return response.message.errors.map(
        (err, index) => `Dòng ${index + 1}: ${err.errors.errors[0].message}`
      );
    } catch (error) {
      return [];
    }
  }

  const handleCancel = () => {
    setIsModalVisible(false);
  };

  return (
    <>
      <Modal
        title={`Cập nhật User (UID: ${user?.id})`}
        open={isModalVisible}
        onOk={validateForm}
        onCancel={handleCancel}
        okText="Cập nhật"
        cancelText="Huỷ thao tác"
        maskClosable={false}
        confirmLoading={isUpdating}
        centered
        width={800}
      >
        <UpdateUserForm form={form} />
      </Modal>
    </>
  );
}
