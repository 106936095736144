import React from "react";
import {
  Form,
  Input,
  InputNumber,
  Select,
  Space,
  Divider,
  DatePicker,
} from "antd";

import { defaultDateFormat } from "helpers/dateTimeHelper";
import { validateMessages, phoneNumberRegex } from "helpers/validationHelper";
import { textAreaAutoSize } from "helpers/textFieldHelper";
import { genderData } from "pages/users_page/helpers";

export default function UpdateUserForm({ form }) {
  const minExYear = 1;
  const maxExYear = 10;
  return (
    <>
      <Form
        // init data fields by 'form' prop
        form={form}
        name="update_user_form"
        layout="vertical"
        size="middle"
        autoComplete="off"
        validateMessages={validateMessages}
      >
        <Form.Item
          label="Tên hiển thị"
          name="display_name"
          rules={[{ required: true }]}
        >
          <Input placeholder="Nhập tên hiển thị mới" />
        </Form.Item>

        <Form.Item
          label="Số điện thoại"
          name="phone"
          rules={[{ required: true, pattern: phoneNumberRegex }]}
        >
          <Input placeholder="Nhập số điện thoại mới" />
        </Form.Item>

        <Space
          wrap={false}
          size={"large"}
          split={<Divider type="vertical" />}
          direction="horizontal"
        >
          <Form.Item label="Giới tính" name="gender">
            <Select options={genderData} style={{ width: 100 }} />
          </Form.Item>
          <Form.Item label="Ngày sinh" name="birthday">
            <DatePicker
              placeholder="Chọn ngày sinh"
              format={defaultDateFormat}
            />
          </Form.Item>

          <Form.Item
            label="Số năm kinh nghiệm"
            name="exp_years"
            rules={[{ type: "number", min: minExYear, max: maxExYear }]}
          >
            <InputNumber />
          </Form.Item>
        </Space>

        <Form.Item label="Địa chỉ" name="address">
          <Input.TextArea
            placeholder="Nhập địa chỉ mới"
            autoSize={textAreaAutoSize}
          />
        </Form.Item>

        <Form.Item label="Chức danh" name="job_title">
          <Input.TextArea
            placeholder="Nhập chức danh mới"
            autoSize={textAreaAutoSize}
          />
        </Form.Item>
      </Form>
    </>
  );
}
