import { authorizedFetch, baseUrl } from './base';

async function login(params) {
  const path = `${baseUrl}/api/v1/authentications/login_by_gsi`;

  return await authorizedFetch(path, {
    method: 'POST',
    body: JSON.stringify(params),
  });
}

// eslint-disable-next-line import/no-anonymous-default-export
export default { login };
