import React from 'react';

// Login.jsx
export default function ContatPage() {
  return (
    <div>
      <h1>This is the About Page</h1>
    </div>
  );
}
