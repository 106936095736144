export const phoneNumberRegex = /\b(\+84|84|0)(3|5|7|8|9)([0-9]{8})\b/;

export const minExYear = 0;
export const maxExYear = 99;

export const validateMessages = {
  default: "${label} không phù hợp!",
  required: "${label} là bắt buộc!",
  pattern: {
    mismatch: "${label} không đúng định dạng!",
  },
  types: {
    email: "${label} không đúng định dạng!",
    number: "${label} không đúng định dạng!",
  },
  number: {
    range: "${label} phải lớn hơn XXX và nhỏ hơn YYY",
    len: "${label} không phù hợp!",
    min: "${label} không phù hợp!",
    max: "${label} không phù hợp!",
  },
  array: {
    len: "${label} không phù hợp!",
    min: "${label} không phù hợp!",
    max: "${label} không phù hợp!",
    range: "${label} không phù hợp!",
  },
  string: {
    len: "${label} không phù hợp!",
    min: "${label} không phù hợp!",
    max: "${label} không phù hợp!",
    range: "${label} không phù hợp!",
  },
  enum: "${label} không phù hợp!",
};
