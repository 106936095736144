import React, { useState, useRef } from "react";
import { StyledTable } from "components/ThemeComponents";
import { Button, message, Popconfirm } from "antd";
import userApis from "api/userApis";
import Avatar from "antd/lib/avatar/avatar";

import UpdateUserModal from "pages/users_page/update_user/components/UpdateUserModal";

export default function UserTable({ data = [], loading }) {
  const [isModalUpdateUserVisible, setIsModalUpdateUserVisible] =
    useState(false);

  const edittingUser = useRef(null);

  const setEdittingUser = (user) => {
    edittingUser.current = user;
  };

  const confirmDeleteUser = async (record) => {
    try {
      const json = await userApis.destroy(record.id);
      if (json.code === 200) {
        window.location.reload();
      } else {
        message.info(json.message);
      }
    } catch (error) {
      message.info(error.message);
    }
  };

  const showModalUpdateUser = (user) => {
    setEdittingUser(user);
    setIsModalUpdateUserVisible(true);
  };

  const columns = [
    {
      title: "Avatar",
      width: "7rem",
      align: "center",
      render: (record) => <Avatar size={80} src={record.avatarUrl} />,
    },
    {
      title: "Tên hiển thị",
      width: "10rem",
      align: "center",
      ellipsis: {
        showTitle: false,
      },
      render: (record) => <span>{record.display_name}</span>,
    },
    {
      title: "Email",
      width: "15rem",
      align: "center",
      ellipsis: {
        showTitle: false,
      },
      render: (record) => <span>{record.email}</span>,
    },
    {
      title: "Số điện thoại",
      width: "10rem",
      align: "center",
      ellipsis: {
        showTitle: false,
      },
      render: (record) => <span>{record.phone ?? "-"}</span>,
    },
    {
      title: "Số bài đăng",
      width: "7rem",
      align: "center",
      ellipsis: {
        showTitle: false,
      },
      render: (record) => <span>{record.posts_count}</span>,
    },
    {
      title: "Giới tính",
      width: "7rem",
      align: "center",
      ellipsis: {
        showTitle: false,
      },
      render: (record) => (
        <span>{record.gender === "male" ? "Nam" : "Nữ"}</span>
      ),
    },
    {
      title: "Ngày sinh",
      width: "7rem",
      align: "center",
      ellipsis: {
        showTitle: false,
      },
      render: (record) => <span>{record.birthday ?? "-"}</span>,
    },
    {
      title: "Số năm kinh nghiệm",
      width: "7rem",
      align: "center",
      render: (record) => <span>{record.exp_years ?? "-"}</span>,
    },
    {
      title: "Địa chỉ",
      width: "7rem",
      align: "center",
      ellipsis: {},
      render: (record) => <span>{record.address ?? "-"}</span>,
    },
    {
      title: "Chức danh",
      width: "7rem",
      align: "center",
      ellipsis: {},
      render: (record) => <span>{record.job_title ?? "-"}</span>,
    },
    {
      title: "Chức năng",
      render: (record) => (
        <>
          <p>
            <Button type="primary" onClick={() => showModalUpdateUser(record)}>
              Cập nhật thông tin
            </Button>
          </p>

          <p>
            <Popconfirm
              placement={top}
              title={"Bạn có chắc muốn xoá tài khoản?"}
              onConfirm={() => confirmDeleteUser(record)}
              okText="Có"
              cancelText="Không"
            >
              <Button type="primary" danger>
                Xóa tài khoản
              </Button>
            </Popconfirm>
          </p>
        </>
      ),
    },
  ];

  const top = "topRight";
  const bottom = "bottomRight";

  return (
    <>
      <StyledTable
        sticky
        size="small"
        scroll={{ x: 1000 }}
        loading={loading}
        rowKey="id"
        // @ts-ignore
        columns={columns}
        dataSource={data}
        pagination={{
          position: [top, bottom],
          showTotal: (total, range) => (
            <span style={{ left: 0, position: "absolute" }}>
              Hiển thị {range[0]}-{range[1]} của {total} tài khoản
            </span>
          ),
        }}
      />
      <UpdateUserModal
        isModalVisible={isModalUpdateUserVisible}
        setIsModalVisible={setIsModalUpdateUserVisible}
        user={{ ...edittingUser.current }}
      ></UpdateUserModal>
    </>
  );
}
