const REM_PX_RATIO = 14;

const round = (value, places) => {
  if (Number.isNaN(value)) return null;
  // @ts-ignore
  return +(Math.round(value + 'e+' + places) + 'e-' + places);
};

const convertAbsUnits = ({ val, to, places = 7, withUnit = false }) => {
  const parsed = parseFloat(val);
  if (Number.isNaN(parsed)) return null;
  let resultNum;
  let resultUnit;
  switch (true) {
    case `${val}`.indexOf('px') > 0 && to === 'px':
    case `${val}`.indexOf('rem') > 0 && to === 'rem':
      resultNum = val;
      break;
    case to === 'px':
      resultNum = round(parsed * REM_PX_RATIO, places);
      if (withUnit) {
        resultUnit = 'px';
      }
      break;
    case to === 'rem':
      resultNum = round(parsed / REM_PX_RATIO, places);
      if (withUnit) {
        resultUnit = 'rem';
      }
      break;
    default:
      resultNum = val;
  }
  if (resultUnit) {
    return `${resultNum}${resultUnit}`;
  }
  return resultNum;
};

export { REM_PX_RATIO, round, convertAbsUnits };
