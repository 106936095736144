import { message } from 'antd';
import React from 'react';
import { useNavigate } from 'react-router-dom';
import { useLocalStorage } from 'hooks/useLocalStorage';
import { currentUserKey } from 'api/base';

// https://blog.devgenius.io/how-to-add-authentication-to-a-react-app-26865ecaca4b
export const useAuth = () => {
  const [user, setUser] = useLocalStorage(currentUserKey, null);
  const navigate = useNavigate();

  // call this function when you want to authenticate the user
  const login = async (data) => {
    setUser(data);

    navigate('/');
    message.success('Đã đăng nhập thành công.');
  };

  // call this function to sign out logged in user
  const logout = () => {
    setUser(null);
    navigate('/login', { replace: true });
  };

  return {
    user,
    login,
    logout
  };
};
