import React from 'react';

import './styles/App.css';
import { BrowserRouter, Routes, Route } from 'react-router-dom';

import Home from 'pages/Home';
import UsersPage from 'pages/UsersPage';
import LayoutAuthenticated from './layouts/Authenticated';
import { RecoilRoot } from 'recoil';
import { message } from 'antd';
import LoginPage from './pages/LoginPage';
import Unauthenticated from './layouts/Unauthenticated';
import { AppRoutes } from './configs/AppRoutes';
import AboutPage from './pages/AboutPage';
import GuidePage from './pages/GuidePage';
import ContactPage from './pages/ContactPage';
import ExampleUploadPage from './pages/examples/ExampleUploadPage';
import { AuthProvider } from 'features/auth/contexts';

function App() {
  message.config({
    duration: 2
  });

  return (
    <RecoilRoot>
      <BrowserRouter>
        <AuthProvider>
          <Routes>
            <Route element={<LayoutAuthenticated />}>
              <Route path="/" element={<Home />} />
              <Route path="users" element={<UsersPage />} />
            </Route>

            <Route element={<Unauthenticated />}>
              <Route path={AppRoutes.Login.path} element={<LoginPage />} />
              <Route path={AppRoutes.About.path} element={<AboutPage />} />
              <Route path={AppRoutes.Guide.path} element={<GuidePage />} />
              <Route path={AppRoutes.Contact.path} element={<ContactPage />} />

              {/* examples components */}
              <Route
                path={AppRoutes.Examples.Upload.path}
                element={<ExampleUploadPage />}
              />
            </Route>
          </Routes>
        </AuthProvider>
      </BrowserRouter>
    </RecoilRoot>
  );
}

export default App;
