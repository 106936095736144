import React, { useCallback, useEffect, useState } from 'react';
import { Menu, message, Popconfirm } from 'antd';
import {
  UserOutlined,
  CommentOutlined,
  NodeExpandOutlined,
  BarsOutlined,
  FileTextOutlined,
  SendOutlined,
  LogoutOutlined,
  UserSwitchOutlined,
  SolutionOutlined,
  RocketOutlined
} from '@ant-design/icons';
import { Link } from 'react-router-dom';

import { AppRoutes } from 'configs/AppRoutes';
import { useAuth } from 'features/auth/hooks';

function getItem(label, key, icon, children) {
  return {
    key,
    icon,
    children,
    label
  };
}

export default function Navigation() {
  const [selectedKeys, setSelectedKeys] = useState([]);
  const [openKeys, setOpenKeys] = useState([]);

  const items = [];
  items.push(
    getItem(
      <Link to={AppRoutes.Users.path}>{AppRoutes.Users.label}</Link>,
      AppRoutes.Users.path,
      <UserOutlined />
    )
  );

  useEffect(() => {
    const hashPath = window.location.href;
    if (hashPath.indexOf(AppRoutes.Users.path) != -1) {
      setSelectedKeys([AppRoutes.Users.path]);
      setOpenKeys([]);
    }
  }, []);

  const onSelectMenuItem = useCallback(
    (item, key) => {
      setSelectedKeys([item.key]);
    },
    [selectedKeys]
  );

  const onOpenChange = useCallback((keys) => {
    setOpenKeys(keys);
  }, []);

  const { logout } = useAuth();

  const handleLogout = () => {
    logout();
    message.success('Đã đăng xuất thành công.');
  };

  items.push(
    getItem(
      <Popconfirm
        placement="topRight"
        title={`Bạn có chắc muốn đăng xuất?`}
        onConfirm={handleLogout}
        okText="Yes"
        cancelText="No"
      >
        <a>Đăng xuất</a>
      </Popconfirm>,
      null,
      <LogoutOutlined />
    )
  );

  return (
    <Menu
      theme="dark"
      mode="inline"
      items={items}
      selectedKeys={selectedKeys}
      onSelect={onSelectMenuItem}
      openKeys={openKeys}
      onOpenChange={onOpenChange}
    ></Menu>
  );
}
