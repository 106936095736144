import React from 'react';
import { Layout } from 'antd';
import DocHead from 'components/DocHead';
import PageLoading from 'components/PageLoading';
import Navigation from 'components/Navigation';
import { Navigate, useNavigate, useOutlet } from 'react-router-dom';
import { useAuth } from 'features/auth/hooks';
import GuestNavigation from 'components/GuestNavigation';

const { Sider } = Layout;

export default function Unauthenticated(status) {
  const navigate = useNavigate();

  const { user } = useAuth();

  const outlet = useOutlet();

  if (status === 'loading') return <PageLoading />;

  return (
    <>
      <Layout hasSider>
        <Sider
          style={{
            overflow: 'auto',
            height: '100vh',
            position: 'fixed',
            left: 0,
            top: 0,
            bottom: 0
          }}
        >
          <div className="logo">
            <a href="/">Admin</a>
          </div>
          <GuestNavigation />
        </Sider>
        <Layout
          className="site-layout"
          style={{
            marginLeft: 200
          }}
        >
          {outlet}
        </Layout>
      </Layout>
    </>
  );
}
