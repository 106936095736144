import React from 'react';
import { Layout } from 'antd';

export default function Footer() {
  return (
    <Layout.Footer
      style={{
        textAlign: 'center',
      }}
    >
      Ant Design ©2018 Created by Ant UED
    </Layout.Footer>
  );
}
