import React, { useEffect } from 'react';

import { Button, Col, Form, Input, message, Row } from 'antd';
import authApis from 'api/authApis';
import Title from 'antd/lib/typography/Title';
import { useNavigate } from 'react-router-dom';
import { useAuth } from 'features/auth/hooks';
import { GoogleLogin } from '@react-oauth/google';

export default function LoginPage() {
  const { login, user, logout } = useAuth();
  const navigate = useNavigate();

  useEffect(() => {
    if (user && user.code === 200) {
      navigate('/');
    }
  }, [navigate, user]);

  const onLogin = async (response) => {
    const gsiToken = response.credential;

    try {
      if (gsiToken) {
        const response = await authApis.login({
          token: gsiToken
        });
        if (response.code === 200) {
          login(response.data);
        } else {
          logout();
          message.error(response.message);
        }
      } else {
        logout();
        message.error('Đã có lỗi xảy ra (CODE: onFinish)');
      }
    } catch (error) {
      message.error(`Đã có lỗi xảy ra (${error.message})`);
    }
  };

  const errorMessage = (error) => {
    console.log(error);
  };

  return (
    <>
      <div
        style={{
          display: 'flex',
          height: '100vh',
          justifyContent: 'center'
        }}
      >
        <div
          style={{
            alignSelf: 'center',
            justifyContent: 'center',
            marginTop: '-25%'
          }}
        >
          <Title level={2} style={{ textAlign: 'center' }}>
            Chào bạn, vui lòng đăng nhập để tiếp tục
          </Title>

          <GoogleLogin onSuccess={onLogin} onError={errorMessage} />
        </div>
      </div>
    </>
  );
}
