import React, { useState } from 'react';
import { Upload, Button, message } from 'antd';
import { UploadOutlined, PlusOutlined } from '@ant-design/icons';
import { customAntdUploadRequestHandler } from '../helpers';

const ALLOW_FILE_FORMATS = ['jpg', 'jpeg', 'bmp', 'png', 'gif', 'heic'];
const ALLOW_FILE_ATTRIBS = ALLOW_FILE_FORMATS.map((i) => `image/${i}`);

const UploadBtn = () => {
  return (
    <div>
      <PlusOutlined />
      <div
        style={{
          marginTop: 8
        }}
      >
        Upload
      </div>
    </div>
  );
};

const ImageUploader = ({
  toFolder = '',
  defaultFileList = [],
  onChange = () => {}
}) => {
  const [loading, setLoading] = useState(false);

  const customRequestHandler = async ({
    file,
    onError,
    onSuccess,
    onProgress
  }) => {
    return customAntdUploadRequestHandler({
      file: file,
      onError: onError,
      onSuccess: onSuccess,
      onProgress: onProgress,
      setLoading: setLoading,
      uploadToFolder: toFolder
    });
  };

  const handleChange = (info) => {
    const uploadedImages = info.fileList.filter(
      (item) => item.status === 'done' && item.record
    );
    const uploadedImageRecords = uploadedImages.map((item) => item.record);
    if (['done', 'removed'].includes(info.file.status)) {
      onChange(uploadedImageRecords);
    }
  };

  return (
    <div>
      <Upload
        defaultFileList={defaultFileList}
        multiple={true}
        disabled={loading}
        accept={ALLOW_FILE_ATTRIBS.join(',')}
        listType="picture-card"
        onChange={handleChange}
        customRequest={customRequestHandler}
      >
        <UploadBtn />
      </Upload>
    </div>
  );
};

export default ImageUploader;
