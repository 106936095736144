import React from 'react';
import { Spin } from 'antd';
import styled from 'styled-components';

const Container = styled.div`
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
`;

const Midpoint = styled.div`
  position: absolute;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
  text-align: center;
`;

export default function PageLoading() {
  return (
    <Container>
      <Midpoint>
        <Spin size="large" />
      </Midpoint>
    </Container>
  );
}
