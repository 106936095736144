import { authorizedFetch, baseUrl, removeEmpty } from './base';
import queryString from 'query-string';

async function list(params = {}) {
  const path = `${baseUrl}/api/v1/agent/users?${queryString.stringify(
    removeEmpty(params)
  )}`;

  return await authorizedFetch(path, {
    method: 'GET',
  });
}

async function create(params) {
  const path = `${baseUrl}/api/v1/agent/users`;

  return await authorizedFetch(path, {
    method: 'POST',
    body: JSON.stringify(params),
  });
}

async function update(userId, params) {
  const path = `${baseUrl}/api/v1/agent/users/${userId}`;

  return await authorizedFetch(path, {
    method: 'PUT',
    body: JSON.stringify(params),
  });
}

async function destroy(userId) {
  const path = `${baseUrl}/api/v1/agent/users/${userId}`;

  return await authorizedFetch(path, {
    method: 'DELETE',
  });
}
const userApis = { list, create, update, destroy };

export default userApis;
