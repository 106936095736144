const AppRoutes = {
  Users: {
    path: '/users',
    label: 'Quản lý tài khoản'
  },
  Posts: {
    path: '/posts',
    label: 'Bài viết'
  },
  Login: {
    path: '/login',
    label: 'Đăng nhập'
  },
  About: {
    path: '/gioi-thieu',
    label: 'Giới thiệu'
  },
  Guide: {
    path: '/huong-dan',
    label: 'Hướng dẫn'
  },
  Contact: {
    path: '/lien-he',
    label: 'Liên hệ'
  },
  Examples: {
    Upload: {
      path: '/docs/uploads',
      label: 'Upload'
    }
  }
};

export { AppRoutes };
