import { authorizedFetch, baseUrl } from './base';

async function trackUploadedImage(params) {
  const path = `${baseUrl}/api/v1/images/track-uploaded`;

  return await authorizedFetch(path, {
    method: 'POST',
    body: JSON.stringify(params)
  });
}

// eslint-disable-next-line import/no-anonymous-default-export
export { trackUploadedImage };
