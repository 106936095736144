import React from 'react';
import AppHeader from 'components/AppHeader';
import ImageUploader from 'features/image_upload/components/ImageUploader';
import {
  IMAGES_EXAMPLES,
  convertToAntdFileList
} from 'features/image_upload/helpers';

export default function ExampleUploadPage() {
  const defaultFileList = convertToAntdFileList(IMAGES_EXAMPLES);

  const handleUploaded = (uploadedImages) => {
    console.log('Uploaded photos', uploadedImages);
  };

  return (
    <>
      <AppHeader pageTitle="Example Uploads" />

      <ImageUploader
        toFolder="test_images"
        defaultFileList={defaultFileList}
        onChange={handleUploaded}
      />
    </>
  );
}
